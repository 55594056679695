@use 'rwd/breakpoints';

html,
body {
  height: 100%;
}

.m-text-center {
  text-align: center;
}
.m-centered {
  margin: 0 auto;
}

.m-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card {
  padding: 40px 48px;
  background-color: var(--white-100);
  box-shadow:
    0px 2px 24px 0px rgba(1, 52, 118, 0.08),
    0px 4px 8px 0px rgba(1, 52, 118, 0.08);
  border-radius: 16px;
}

.card-plain {
  padding: 40px 48px;
  background-color: var(--white-100);
  border-radius: 16px;
}

.inner-dialog {
  max-width: 360px;
  margin: 2.5em 3em 2.5em 3em;

  .strong {
    font-weight: 800;
  }

  .top-text {
    margin-block: 20px;
  }

  .bottom-text {
    margin-bottom: 5px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
// Overwrite dialog backdrop color
.cdk-overlay-backdrop {
  background-color: rgb(
    from var(--color-grey-400) r g b / 15%
  ); // It seems hacky, but  rgba(var(--color-grey-400), 0.15) is not working here
}

.dialog-close-button {
  color: var(--text-heading-primary);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app-menu {
  background-color: var(--white-100); //var(--color-grey-50);

  a {
    padding: 12px 24px 12px 36px;
    text-decoration: none;
    color: var(--text-heading-primary);
    font-family: 'Thieme', sans-serif;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 12px;
    fill: var(--color-blue-400);

    &.is-current {
      border-right: 4px solid var(--color-dark-blue-700);
      background-color: var(--color-blue-100);
    }

    .icon {
      margin-top: 3px;
    }
  }
}

.b-breadcrumbs {
  padding-bottom: 20px;
  span {
    color: var(--color-grey-500);
    position: relative;
    padding-right: 26px;

    &:not(:last-of-type):after {
      position: absolute;
      content: '>';
      top: 0;
      right: 8px;
      color: var(--color-grey-500);
      line-height: 1.2;
    }
  }
  span:last-of-type {
    color: var(--color-dark-blue-700);
    font-weight: bold;
  }
}

.cdk-overlay-pane {
  // overflow: auto;
  max-height: 100vh;
}

.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
